<template>
  <div :class="['content_style',text==3?'bgorange':(text==2?'bgblue':(text==1?'bggreen':(text==4?'bgpink':(text==0?'bggrey':''))))]">
    {{ $t('carstatus')[status] }}
  </div>
</template>
<script>
export default {
  props: {
    text: {
        type: Number,
        default: null
    }
  },
  data () {
    return {}
  },
  computed: {
    status () {
      return this.text ? 1 : 0
    }
  }
}
</script>
<style lang="less" scoped>
.content_style{
    width: 80px;
    line-height: 28px;
    height: 28px;
    text-align: center;
    color:#fff;
    border-radius: 5px;
    margin: 0 auto;
}
.bgpink{
    background: #ff4545;
}
.bgorange{
    background: #ffa701;
}
.bgblue{
    background:#3b8eff;
}
.bggreen{
    background: #39c38d;
}
.bggrey{
    background: rgba(0, 0, 0, 0.85);
}
.anticon {
  margin-right: 6px;
  font-size: 24px;
}
</style>
