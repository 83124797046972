var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "content_style",
        _vm.text == 3
          ? "bgorange"
          : _vm.text == 2
          ? "bgblue"
          : _vm.text == 1
          ? "bggreen"
          : _vm.text == 4
          ? "bgpink"
          : _vm.text == 0
          ? "bggrey"
          : "",
      ],
    },
    [_vm._v(" " + _vm._s(_vm.$t("carstatus")[_vm.status]) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }